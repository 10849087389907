<template>
    <div
        class="datepicker-component"
        :class="{ 'full-week': isFullWeek }"
        v-click-outside="() => {this.open = false}">
        <input
            type="text"
            v-model="dateString"
            readonly="readonly"
            @click="open = !open"
            class="input-date"
            :class="{ 'open': open }"
            :disabled="disabled">

        <md-icon class="icon-calendar" v-show="!disabled">calendar_today</md-icon>
        <span class="week-text" v-show="weekText && !disabled">{{ weekText }}</span>

        <date-pick
            v-model="date"
            v-show="open"
            :hasInputElement="false"
            :nextMonthCaption="lang.nextMonthCaption"
            :prevMonthCaption="lang.prevMonthCaption"
            :setTimeCaption="lang.setTimeCaption"
            :weekdays="lang.weekdays"
            :months="lang.months"
            :isDateDisabled="isPastDate"
        ></date-pick>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'
import moment from '../../plugins/moment'

// https://dbrekalo.github.io/vue-date-pick/
import DatePick from 'vue-date-pick'

Vue.use(MdIcon)

export default {
    name: 'Datepicker',
    props: ['initDate', 'isFullWeek', 'disabled', 'transportId'],

    data() {
        return {
            date: this.initDate,
            open: false,
            lang: {
                nextMonthCaption: this.$t('common.datepicker.next_month_caption'),
                prevMonthCaption: this.$t('common.datepicker.prev_month_caption'),
                setTimeCaption: this.$t('common.datepicker.set_time_caption'),
                weekdays: [
                    this.$t('common.datepicker.weekdays.mo'),
                    this.$t('common.datepicker.weekdays.tu'),
                    this.$t('common.datepicker.weekdays.we'),
                    this.$t('common.datepicker.weekdays.th'),
                    this.$t('common.datepicker.weekdays.fr'),
                    this.$t('common.datepicker.weekdays.sa'),
                    this.$t('common.datepicker.weekdays.su'),
                ],
                months: [
                    this.$t('common.datepicker.months.jan'),
                    this.$t('common.datepicker.months.feb'),
                    this.$t('common.datepicker.months.mar'),
                    this.$t('common.datepicker.months.apr'),
                    this.$t('common.datepicker.months.may'),
                    this.$t('common.datepicker.months.jun'),
                    this.$t('common.datepicker.months.jul'),
                    this.$t('common.datepicker.months.aug'),
                    this.$t('common.datepicker.months.sep'),
                    this.$t('common.datepicker.months.oct'),
                    this.$t('common.datepicker.months.nov'),
                    this.$t('common.datepicker.months.dec')
                ]
            }
        }
    },

    watch: {
        date: function(val, old) {
            if (this.isFullWeek) {
                val = moment(val, 'YYYY-MM-DD').startOf('week').format('YYYY-MM-DD')
            }

            if (val === 'Invalid date') {
                let oldSelected = document.querySelectorAll('.full-week tr.tr-selected')
                oldSelected.forEach(element => {
                    element.classList.remove('tr-selected')
                })
                this.$emit('changed', null)
            }

            if (old !== null && this.open === true) {
                this.$emit('changed', val)
                this.open = false
            }
        },

        'initDate': function(val) {
            if (val !== null) {
                this.date = val
            }
        },
    },

    mounted() {
        if (this.isFullWeek) {
            this.date = moment(this.date, 'YYYY-MM-DD').startOf('week').format('YYYY-MM-DD')

            let setWeekRow = () => {
                let oldSelected = document.querySelectorAll('.full-week tr.tr-selected')
                oldSelected.forEach(element => {
                    element.classList.remove('tr-selected')
                })

                setTimeout(function() {
                    let selectedTd = document.querySelector('.full-week .vdpCell.selected')
                    if (selectedTd) {
                        selectedTd.parentElement.classList.add('tr-selected')
                    }
                }, 1)
            }

            document
                .querySelector('.full-week .vdpComponent')
                .addEventListener('click', setWeekRow)
            setWeekRow()
        }
    },

    methods: {
        isPastDate(date) {
            let disabledToDate = new Date(moment().subtract(1, 'months').endOf('month'))

            if (this.isFullWeek) {
                disabledToDate = new Date(moment().subtract(1, 'weeks').endOf('week'))
            }

            // bramac szallit
            if (this.transportId == 1 && (moment().weekday() == 3 || moment().weekday() == 4)) {
                disabledToDate = new Date(moment().endOf('week'))
            }

            return date < disabledToDate
        }
    },

    computed: {
        dateString() {
            if (this.date === 'Invalid date') {
                return ''
            }

            if (this.isFullWeek) {
                const weekBegin = moment(this.date, 'YYYY-MM-DD').startOf('week')
                const weekEnd = moment(this.date, 'YYYY-MM-DD').endOf('week')

                return `${weekBegin.format(String(this.$t('common.date.format.week_range')))} - ${weekEnd.format(String(this.$t('common.date.format.week_range')))}`
            }

            return moment(this.date, 'YYYY-MM-DD').format(String(this.$t('common.date.format.long')))
        },

        weekText() {
            if (this.date === 'Invalid date') {
                return ''
            }

            if (this.isFullWeek) {
                const weekDate = moment(this.date, 'YYYY-MM-DD')

                return this.$t('common.datepicker.week_text', {week: weekDate.isoWeek(), year: weekDate.isoWeekYear()})
            }

            return null
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    components: { DatePick }
}
</script>

<style lang="scss">
@import '../../../node_modules/vue-date-pick/dist/vueDatePick.css';
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/datepick.scss';

.datepicker-component {
    position: relative;

    &.full-week {
        .input-date {
            padding-top: 22px;
            padding-bottom: 6px;
        }

        .vdpRow {
            .vdpCell {
                border-radius: 0;

                &:first-child {
                    border-top-left-radius: 40px;
                    border-bottom-left-radius: 40px;
                }

                &:last-child {
                    border-top-right-radius: 40px;
                    border-bottom-right-radius: 40px;
                }

                &.selected div {
                    color: $color-white !important;
                    background: transparent !important;
                }

                &.selected.outOfRange div {
                    color: $color-black-1 !important;
                }
            }

            &.tr-selected {
                .vdpCell {
                    background: $color-red-1;
                    color: $color-white !important;

                    &:hover div {
                        background: $color-red-1 !important;
                        color: $color-white !important;
                    }
                }
            }
        }
    }
}
</style>
